<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Due Date"
                rules="required"
              >
                <TextInput
                  v-model="form.dueDate"
                  type="date"
                  :label="$t('Due Date')"
                  :tooltipMessage="$t('Due Date')"
                  :required="true"
                  :key="form.dueDate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Free Users"
                rules="required"
              >
                <TextInput
                  v-model="form.freeUsers"
                  type="number"
                  :label="$t('Free Users')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <TextInput
                v-model="form.createdUser"
                type="text"
                :label="$t('Creator')"
                :required="true"
                :disabled="true"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-12">
              <label for="cars">{{ $t("Notes") }}</label>
              <textarea class="form-control" rows="5" v-model="form.notes" />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="createCoupon"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Create Coupon") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Coupon Codes"),
          to: "/coupon-codes",
        },
        {
          text: this.$t("Create"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      form: {
        dueDate: "",
        freeUsers: "1",
        createdUser: "",
        notes: "",
      },
    };
  },
  async mounted() {
    this.form.dueDate = this.calculateFutureDate(30);
    let response = await this.$store.dispatch("users/show", {
      id: localStorage.getItem("user_id"),
    });
    this.form.createdUser =
      response?.data?.first_name + " " + response?.data?.last_name;
  },
  methods: {
    calculateFutureDate(days) {
      const today = new Date();
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + days);

      // Format the date as YYYY-MM-DD
      const year = futureDate.getFullYear();
      const month = String(futureDate.getMonth() + 1).padStart(2, "0");
      const day = String(futureDate.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    async createCoupon() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store.dispatch("coupons/create", {
            ...this.form,
          });
          this.$router.push("/coupon-codes");
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
